import React from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Form, Input, notification } from "antd";
import { Col, Row, Select, Modal, InputNumber } from "antd";
import { MaskedInput } from "@/ui";
import { Spinner } from "@/ui/Loading";
import { CheckCircleOutlined } from "@ant-design/icons";
import { SET_APP, GET_CLIENT } from "@/actions/app";
import { call, call_sso } from "@/actions/axios";

const { Option } = Select;
const { confirm } = Modal;

const Step = (props) => {
  const { history, match } = props;
  const [client, setClient] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [binSearching, setBinSearching] = React.useState(false);
  const [binError, setBinError] = React.useState("");

  const [phoneError, setPhoneError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [treatyError, setTreatyError] = React.useState("");

  const isNew = match.params.cust_id === "new";

  React.useEffect(() => {
    const getClient = async () => {
      try {
        const client = await dispatch(GET_CLIENT(match.params.cust_id));
        let contact = [];
        if (client.contact_person) contact = client.contact_person.split(" ");

        const values = {
          bin: client.cust_bin,
          allowed_sessions: client.allowed_sessions,
          treaty_number: client.treaty_number,
          contact_phone: client.contact_person_phone,
          contact_email: client.contact_person_email,
          operators_allowed_to_create: client.operators_allowed_to_create,
        };
        if (contact[0]) values.contact_firstName = contact[0];
        if (contact[1]) values.contact_lastName = contact[1];
        const services_main = [];
        const services_extra = [];
        if (client.allowed_campaign_types) {
          if (client.allowed_campaign_types.indexOf(1) !== -1)
            services_main.push("notification");
          if (client.allowed_campaign_types.indexOf(2) !== -1)
            services_main.push("call");
          if (client.allowed_campaign_types.indexOf(3) !== -1)
            services_extra.push("targetting");
        }
        if (client.record_audio_allowed) services_extra.push("recording");
        if (client.tts_allowed) services_extra.push("tts");
        if (client.crm_integration_allowed) services_extra.push("integration");
        if (client.scripts_allowed) services_extra.push("scripts_allowed");
        values.services_main = services_main;
        values.services_extra = services_extra;
        setClient(client);
        form.current.setFieldsValue(values);
      } catch (e) {
        console.log(e.message);
        history.push("/");
      }
    };
    if (!isNew) getClient();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const form = React.useRef();
  const dispatch = useDispatch();

  const updateUser = async (values, method) => {
    await dispatch(
      call_sso({
        url: `api/user`,
        method,
        data: {
          username: values.contact_phone,
          firstName: values.contact_firstName,
          lastName: values.contact_lastName,
          email: values.contact_email,
        },
      })
    );
  };

  const assignServiceToUser = async (values) => {
    //Добавляем сервис автодайлер и роль
    await dispatch(
      call_sso({
        url: `api/user/service-profile`,
        method: "POST",
        data: {
          bin: values.bin,
          profile: "supervisor",
          serviceName: "autodialer",
          username: values.contact_phone,
        },
      })
    );
  };

  const assignUserToClient = async (values) => {
    //Привязываем к клиенту если не привязан
    await dispatch(
      call_sso({
        url: `api/user/assign`,
        method: "POST",
        data: {
          bin: values.bin,
          username: values.contact_phone,
        },
      })
    );
  };

  const updateStatus = async (cust_id, status) => {
    await dispatch(
      call({
        url: `api/admin/customers/${cust_id}/change_status`,
        method: "POST",
        data: { service_active: status },
      })
    );
  };

  const onSubmit = async () => {
    let values = await form.current.validateFields();
    setLoading(true);
    try {
      if (!isNew) await checkBin();
    } catch (e) {
      return setLoading(false);
    }
    values.contact_phone = values.contact_phone.replace(/[^0-9]/g, "");
    if (!values.contact_phone || values.contact_phone.length !== 11) {
      return setPhoneError("Некорректный формат телефонного номера");
    }

    if (!/^[a-zA-Z0-9._]+$/.test(values.treaty_number)) {
      return setTreatyError("Некорректный формат лицевого счета");
    }
    // api/user/check-user
    let checkData;
    try {
      const response = await dispatch(
        call_sso({
          url: `api/user/check-user`,
          data: {
            username: values.contact_phone,
            email: values.contact_email,
          },
          method: "POST",
        })
      );
      checkData = response.data;
    } catch (e) {
      setLoading(false);
      return errorNotification(
        "Не удалось получить информацию о контактного лице из Keycloak"
      );
    }

    //Номер свободен, но емейл кемто используется
    if (checkData.username === "free") {
      if (checkData.email === "busy") {
        setEmailError("Данный Почтовый адрес уже используется");
        setLoading(false);
        return errorNotification("Данный Почтовый адрес уже используется");
      } else {
        await updateUser(values, "POST");
        await assignUserToClient(values);
        await assignServiceToUser(values);
      }
    } else {
      const { data: user } = await dispatch(
        call_sso({ url: `api/user/${values.contact_phone}` })
      );
      if (checkData.email === "busy") {
        if (user.email !== values.contact_email) {
          setEmailError("Данный Почтовый адрес уже используется");
          setLoading(false);
          return errorNotification("Данный Почтовый адрес уже используется");
        }
      }
      //Обновляем инфу пользователя ССО
      await updateUser(values, "PUT");
      if (user.customers.length > 0) {
        const customer = _.find(user.customers, { idDocNum: values.bin });
        if (!customer) await assignUserToClient(values);
      } else {
        await assignUserToClient(values);
      }
      await assignServiceToUser(values);
    }

    let services = [];

    if (values.services_main) {
      if (values.services_main.indexOf("call") !== -1) services.push(2);
      if (values.services_main.indexOf("notification") !== -1) services.push(1);
    }
    const extra_services = {};
    if (values.services_extra) {
      if (values.services_extra.indexOf("notification_dtmf") !== -1)
        services.push(4);
      if (values.services_extra.indexOf("targetting") !== -1) services.push(3);
      extra_services.tts_allowed =
        values.services_extra.indexOf("tts") !== -1 ? 1 : 0;
      extra_services.record_audio_allowed =
        values.services_extra.indexOf("recording") !== -1 ? 1 : 0;
      extra_services.crm_integration_allowed =
        values.services_extra.indexOf("integration") !== -1 ? 1 : 0;
      extra_services.scripts_allowed =
        values.services_extra.indexOf("scripts_allowed") !== -1 ? 1 : 0;
    }

    services.sort((a, b) => a - b);

    const newValues = {
      allowed_sessions: values.allowed_sessions,
      treaty_number: values.treaty_number,
      contact_person: values.contact_lastName + " " + values.contact_firstName,
      contact_person_phone: values.contact_phone,
      contact_person_email: values.contact_email,
      allowed_campaign_types: services,
      operators_allowed_to_create: values.operators_allowed_to_create,
      admin_comment: values.admin_comment,
      ...extra_services,
    };

    if (isNew) {
      try {
        // api/admin/customers
        const { data: sipCustomer } = await dispatch(
          call({
            url: `api/admin/customers`,
            method: "POST",
            data: {
              cust_bin: values.bin,
              ...newValues,
            },
          })
        );
        await dispatch(
          call({
            url: `/api/customer/operators/msisdns/assigned/${sipCustomer.cust_id}/${values.contact_phone}`,
            method: "POST",
            data: {
              email: values.contact_email,
              msisdn: values.contact_phone,
              represented_name:
                values.contact_lastName + " " + values.contact_firstName,
              role: "SUPERVISOR",
            },
          })
        );
        if (!values.status) await updateStatus(sipCustomer.cust_id, 0);
      } catch (e) {
        setLoading(false);
        return errorNotification(e.message);
      }
    } else {
      try {
        await dispatch(
          call({
            url: `api/admin/customers/${match.params.cust_id}`,
            method: "PATCH",
            data: {
              ...newValues,
            },
          })
        );
        if (client.service_active !== values.status) {
          await updateStatus(match.params.cust_id, values.status);
        }
      } catch (e) {
        setLoading(false);
        return errorNotification(e.message);
      }
    }
    dispatch(SET_APP(["client"], null));
    setLoading(false);
    confirm({
      title: "Клиент успешно " + (isNew ? "добавлен" : "обновлен"),
      icon: <CheckCircleOutlined className="alert-icon" />,
      content: "Желаете вернуться к списку клиентов?",
      okText: "Добавить еще",
      cancelText: "Вернуться",
      onOk() {
        form.current.resetFields();
      },
      onCancel() {
        history.push("/");
      },
    });
  };

  const errorNotification = (description) => {
    return notification.error({
      message: "Ошибка!",
      description,
    });
  };

  const checkBin = async () => {
    setBinSearching(true);
    const values = await form.current.getFieldsValue();
    const { bin } = values;
    if (!bin || bin.length !== 12) {
      setBinSearching(false);
      return setBinError("Некоректный формат поля БИН");
    }
    try {
      const { data } = await dispatch(call_sso({ url: `api/customer/${bin}` }));
      if (Object.keys(data.services).indexOf("autodialer") === -1) {
        setBinSearching(false);
        return setBinError(
          "Услугу Автодайлер не подключена к данному клиенту в Keycloak"
        );
      }
    } catch (e) {
      setBinSearching(false);
      return setBinError("Клиент с таким БИН не заведен в Keycloak");
    }
    try {
      if (!isNew) return;
      await dispatch(call({ url: `api/admin/customers?bin=${bin}` }));
      setBinSearching(false);
      return setBinError("Данный клиент уже заведен в SIP");
    } catch (e) {
      setBinSearching(false);
    }
  };

  return (
    <>
      <Form layout="vertical" ref={form} initialValues={{ status: 1 }}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="БИН"
              name="bin"
              {...(binError && {
                help: binError,
                validateStatus: "error",
              })}
              rules={[
                {
                  required: true,
                  message: "Данное поле обязательно",
                },
              ]}
            >
              <Input
                disabled={loading || binSearching || !isNew}
                maxLength={12}
                suffix={binSearching && <Spinner fontSize={16} />}
                onBlur={checkBin}
                onKeyUp={() => setBinError("")}
                placeholder="Введите БИН"
              />
            </Form.Item>
            <Form.Item
              label="Лицевой счет"
              name="treaty_number"
              {...(treatyError && {
                help: treatyError,
                validateStatus: "error",
              })}
              rules={[
                {
                  required: true,
                  message: "Данное поле обязательно",
                },
              ]}
            >
              <Input
                disabled={loading}
                onKeyUp={() => setTreatyError("")}
                placeholder="Введите лицевой счет"
              />
            </Form.Item>
            <Form.Item
              label="Фамилия контактного лица"
              name="contact_lastName"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input disabled={loading} placeholder="Введите фамилию" />
            </Form.Item>
            <Form.Item
              label="Имя контактного лица"
              name="contact_firstName"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input disabled={loading} placeholder="Введите имя" />
            </Form.Item>
            <Form.Item
              label="Телефон контактного лица"
              name="contact_phone"
              {...(phoneError && {
                help: phoneError,
                validateStatus: "error",
              })}
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <MaskedInput
                mask="+7 111 111 11 11"
                disabled={loading}
                onKeyUp={() => setPhoneError("")}
              />
            </Form.Item>
            <Form.Item
              label="Почтовый адрес контактного лица"
              name="contact_email"
              {...(emailError && {
                help: emailError,
                validateStatus: "error",
              })}
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input
                disabled={loading}
                placeholder="Введите почтовый адрес"
                onKeyUp={() => setEmailError("")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Лимит по единовременным разговорам (SIP сессиям)"
              name="allowed_sessions"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <InputNumber
                disabled={loading}
                placeholder="Введите количество"
              />
            </Form.Item>
            <Form.Item
              label="Лимит по количеству операторов"
              name="operators_allowed_to_create"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <InputNumber
                disabled={loading}
                placeholder="Введите количество"
              />
            </Form.Item>
            <Form.Item
              label="Статус"
              name="status"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Select disabled={loading} placeholder="Выберите статус">
                <Option value={0}>Неактивный</Option>
                <Option value={1}>Активный</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Основные доступные сервисы"
              name="services_main"
              rules={[
                {
                  required: true,
                  type: "array",
                  message: "Данное поле обязательно",
                },
              ]}
            >
              <Select
                disabled={loading}
                mode="multiple"
                placeholder="Выберите сервисы"
              >
                <Option value="call">Обратный дозвон</Option>
                <Option value="notification">Автоинформатор</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Дополнительные сервисы"
              name="services_extra"
              rules={[{ type: "array" }]}
            >
              <Select
                disabled={loading}
                mode="multiple"
                placeholder="Выберите сервисы"
              >
                <Option value="notification_dtmf">Автоинформатор с DTMF</Option>
                <Option value="targetting">Таргетированный контакт</Option>
                <Option value="integration">Интеграции (CRM)</Option>
                <Option value="tts">Text to speech</Option>
                <Option value="recording">Запись разговоров</Option>
                <Option value="scripts_allowed">Сценарии</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Комментарий" name="admin_comment">
              <Input disabled={loading} placeholder="Введите текст" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="buttons">
        <Button
          onClick={onSubmit}
          type="success"
          style={{ marginLeft: 14 }}
          loading={loading}
        >
          {isNew ? "Добавить" : "Обновить"}
        </Button>
        <Button onClick={() => loading || history.push("/")} type="light">
          Назад
        </Button>
      </div>
    </>
  );
};

export default withRouter(Step);

import React from "react";
import { connect, useDispatch } from "react-redux";
import { PageHeader, Card, Descriptions } from "antd";
import { Button, notification } from "antd";
import { call } from "@/actions/axios";
import { GET_CLIENT } from "@/actions/app";
import defaults from "@/defaults";
import Socket from "@/ui/Socket";
import "./index.less";
import { Loading, AudioPlayer } from "@/ui";

const Screen = (props) => {
  const { audio, match, history } = props;
  const dispatch = useDispatch();
  const [client, setClient] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const socket = React.useRef();

  React.useEffect(() => {
    socket.current = Socket("audios");
    if (!audio) return props.history.push("/audios");
    const getClient = async () => {
      const client = await dispatch(GET_CLIENT(audio.cust_id));
      console.log(client);
      setClient(client);
    };
    getClient();
    return () => {
      socket.current.disconnect();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!audio) return null;

  const url = defaults.cabinetURL + "audios/audio/" + audio.id;

  /*
  const uploadAudio = async () => {
    const data = await toDataURL(url);
    const file = await dataURLtoFile(data, uniqid());
    const formData = new FormData();
    formData.append("file", file);
    const headers = {
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/form-data",
    };
    return await dispatch(
      call({
        url: `api/customer/audios/private/${audio.cust_id}`,
        method: "POST",
        data: formData,
        headers,
      })
    );
  };*/

  const onAction = (action) => async () => {
    setLoading(true);
    try {
      if (action === "accept") {
        const { data } = await dispatch(
          call({
            url: defaults.cabinetURL + `audios/${match.params.id}/upload`,
            method: "POST",
          })
        );
        const { announcement_file_id } = data;
        await dispatch(
          call({
            url: `audios/${match.params.id}`,
            method: "PATCH",
            data: { announcement_file_id, status: "approved" },
          })
        );
      } else {
        await dispatch(
          call({
            url: `audios/${match.params.id}`,
            method: "PATCH",
            data: { status: "rejected" },
          })
        );
      }
      socket.current.emit("audio-updated");
      history.push("/audios");
    } catch (e) {
      notification.error({
        message: "Ошибка!",
        description: "Не удалось обновить статус",
      });
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      <PageHeader
        onBack={() => props.history.push("/audios")}
        title="Предварительный просмотр"
        extra={[
          <Button key="targets" type="success" onClick={onAction("accept")}>
            Подтвердить
          </Button>,
          <Button key="aons" type="error" onClick={onAction("reject")}>
            Отказать
          </Button>,
        ]}
      />
      {client ? (
        <Card bordered={false} className="create-container">
          <AudioPlayer url={url} />
          <Descriptions bordered>
            <Descriptions.Item label="Клиент">
              {client.tic_name}
            </Descriptions.Item>
            <Descriptions.Item label="БИН" span={2}>
              {client.cust_bin}
            </Descriptions.Item>
            <Descriptions.Item label="Название аудио">
              {audio.name}
            </Descriptions.Item>
            <Descriptions.Item label="Описание" span={2}>
              {audio.description}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      ) : (
        <Loading />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  audio: state.app.audio,
  token: state.auth.token,
});

export default connect(mapStateToProps)(Screen);

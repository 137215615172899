import React from "react";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import { PageHeader, Input, Button, Card } from "antd";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Table from "./Table";
import { SET_APP, GET_CLIENTS } from "@/actions/app";
import "./index.less";

const Screen = (props) => {
  const { data, loading, history } = props;
  const [search, setSearch] = React.useState("");
  const onCreate = () => {
    props.history.push("/clients/new");
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(SET_APP(["client"], null));
    dispatch(GET_CLIENTS());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = (value) => {
    setSearch(value);
  };

  const dataSource = () => {
    const find = (value) => {
      let str = "";
      if (value) {
        str = value.toLowerCase();
        if (str.includes(search)) return true;
      }
      return false;
    };
    const items = _.filter(data, (o) => {
      if (find(o.tic_name) || find(o.cust_bin) || find(o.treaty_number))
        return o;
    });
    return items;
  };

  return (
    <>
      <PageHeader
        title="Список клиентов"
        subTitle={<SearchInput onChangeText={onSearch} value={search} />}
        extra={[
          <Button key="create" type="success" onClick={onCreate}>
            Добавить клиента
          </Button>,
        ]}
      />
      <Card bordered={false}>
        <Table dataSource={dataSource()} loading={loading} history={history} />
      </Card>
    </>
  );
};

const SearchInput = (props) => {
  const { value, onChangeText } = props;
  const onChange = (e) => {
    onChangeText(e.target.value);
  };
  return (
    <Input
      placeholder="Поиск"
      value={value}
      onChange={onChange}
      suffix={
        value ? (
          <CloseCircleOutlined onClick={() => onChangeText("")} />
        ) : (
          <SearchOutlined className="search-icon" />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  data: state.app.clients,
  loading: state.app.clientsLoading,
});

export default connect(mapStateToProps)(Screen);

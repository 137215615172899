import React from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { PageHeader, Button, Card } from "antd";
import Table from "./Table";
import { SET_APP, SET_APPS } from "@/actions/app";
import { call } from "@/actions/axios";
import "./index.less";

const Screen = (props) => {
  const { data, history, loading, client } = props;

  const onCreate = () => {
    history.push(`/clients/${client.cust_id}/agroups/new`);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!client) return props.history.push("/");
    const { cust_id } = client;
    dispatch(SET_APP(["agroup"], null));
    const onData = async () => {
      dispatch(SET_APP(["agroupsLoading"], true));
      const temp = [];
      try {
        const { data } = await dispatch(
          call({ url: "/api/admin/aons/sets/" + cust_id })
        );

        const array = data.a_number_aons_set_id;
        Object.keys(array).forEach((key) =>
          temp.push({ ...array[key], id: key })
        );
      } catch (e) {
        return dispatch(SET_APP(["agroupsLoading"], false));
      }
      dispatch(
        SET_APPS([
          { path: ["agroups"], value: temp },
          { path: ["agroupsLoading"], value: false },
        ])
      );
    };
    onData();
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const backUrl = localStorage.getItem("backUrl");

  if (!client) return null;

  return (
    <>
      <PageHeader
        title={client.tic_name}
        onBack={() => history.push(backUrl ? backUrl : "/aons")}
        subTitle="Список групп АОН"
        extra={[
          <Button key="create" type="success" onClick={onCreate}>
            Создать группу АОН
          </Button>,
        ]}
      />
      <Card bordered={false}>
        <Table dataSource={data} loading={loading} history={props.history} />
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: state.app.client,
  data: state.app.agroups,
  loading: state.app.agroupsLoading,
});

export default connect(mapStateToProps)(Screen);

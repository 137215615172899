import React from "react";
import { useDispatch } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { LOGOUT } from "@/actions/auth";
import { Layout, Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

import "./index.less";

const { Header } = Layout;

const H = (props) => {
  const { pathname } = props.location;
  const dispatch = useDispatch();

  const hideHeader = ["/access"];
  if (hideHeader.indexOf(pathname) !== -1) return <div />;

  let selectedKeys = "/" + pathname.split("/")[1];
  if (selectedKeys === "/clients") selectedKeys = "/";

  const logout = () => {
    dispatch(LOGOUT());
  };

  return (
    <Header className="main__header">
      <Menu theme="dark" mode="horizontal" selectedKeys={[selectedKeys]}>
        <Menu.Item key="/">
          <NavLink to="/">Клиенты</NavLink>
        </Menu.Item>
        <Menu.Item key="/audios">
          <NavLink to="/audios">Модерация автоинформатора</NavLink>
        </Menu.Item>
        <Menu.Item key="/templates" disabled={true}>
          <NavLink to="/templates">Шаблоны аудиороликов</NavLink>
        </Menu.Item>
        <Menu.Item className="exit__button" key="/exit" onClick={logout}>
          <a href="#?">
            <LogoutOutlined /> Выход
          </a>
        </Menu.Item>
      </Menu>
    </Header>
  );
};

export default withRouter(H);

import AXIOS from "axios";
import _ from "lodash";
import https from "https";
import defaults from "@/defaults";

export const axios = AXIOS.create({
  baseURL: defaults.baseURL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

export const call = (params) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    if (!params.headers) params.headers = {};
    if (token) params.headers.Authorization = `Bearer ${token}`;

    return axios(params);
  };
};

export const axios_ldap = AXIOS.create({
  auth: {
    username: defaults.SSO_USERNAME,
    password: defaults.SSO_PASSWORD,
  },
});

export const axios_sso = AXIOS.create({
  baseURL: defaults.SSO_API_URL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  auth: {
    username: defaults.SSO_USERNAME,
    password: defaults.SSO_PASSWORD,
  },
});

axios_sso.interceptors.response.use(
  (res) => res,
  async (e) => {
    let message = "Неизвестная ошибка";
    const {
      response: { data },
    } = e;
    if (_.isObject(data)) {
      const {
        errors: [{ defaultMessage }],
      } = data;
      message = defaultMessage;
    } else if (_.isString(data)) message = data;

    throw Error(message);
  }
);

export const call_sso = (params) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    if (!params.headers) params.headers = {};
    if (token) params.headers.Authorization = `Bearer ${token}`;

    return axios_sso(params);
  };
};

import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { Layout } from "antd";
import {
  userIsAuthenticatedRedir,
  userIsNotAuthenticatedRedir,
  userIsAuthenticated,
} from "./auth";

import Login from "./screens/Login";
import Access from "./screens/Access";
import NotFound from "./screens/NotFound";

import Clients from "./screens/Clients";
import ClientNew from "./screens/ClientNew";
import ClientPreview from "./screens/ClientPreview";
import Aons from "./screens/Aons";
import AonNew from "./screens/AonNew";
import Reports from "./screens/Reports";
import ReportNew from "./screens/ReportNew";
import Targets from "./screens/Targets";
import TargetNew from "./screens/TargetNew";
import AGroups from "./screens/AGroups";
import AGroupNew from "./screens/AGroupNew";
import Audios from "./screens/Audios";
import AudioPreview from "./screens/AudioPreview";

import { Header } from "./ui";

import "antd/dist/antd.less";
import "./App.less";

const Head = userIsAuthenticated(() => <Header />);
const LoginView = userIsNotAuthenticatedRedir(Login);
const ClientsView = userIsAuthenticatedRedir(Clients);
const ClientNewView = userIsAuthenticatedRedir(ClientNew);
const ClientPreviewView = userIsAuthenticatedRedir(ClientPreview);
const AonsView = userIsAuthenticatedRedir(Aons);
const AonNewView = userIsAuthenticatedRedir(AonNew);
const ReportsView = userIsAuthenticatedRedir(Reports);
const ReportNewView = userIsAuthenticatedRedir(ReportNew);
const TargetsView = userIsAuthenticatedRedir(Targets);
const TargetNewView = userIsAuthenticatedRedir(TargetNew);
const AGroupsView = userIsAuthenticatedRedir(AGroups);
const AGroupNewView = userIsAuthenticatedRedir(AGroupNew);
const AudiosView = userIsAuthenticatedRedir(Audios);
const AudioPreviewView = userIsAuthenticatedRedir(AudioPreview);
const { Content } = Layout;

const App = () => {
  const p = "/clients/:cust_id"; //rootPath
  return (
    <BrowserRouter>
      <Layout className="main__wrapper">
        <Layout>
          <Head />
          <Content className="main__content__layout">
            <Switch>
              <Route exact path="/" component={ClientsView} />
              <Route exact path={p} component={ClientNewView} />
              <Route
                exact
                path={`${p}/preview`}
                component={ClientPreviewView}
              />
              <Route exact path={`${p}/aons`} component={AonsView} />
              <Route exact path={`${p}/aons/:id`} component={AonNewView} />
              <Route exact path={`${p}/agroups`} component={AGroupsView} />
              <Route
                exact
                path={`${p}/agroups/:id`}
                component={AGroupNewView}
              />
              <Route exact path={`${p}/reports`} component={ReportsView} />
              <Route
                exact
                path={`${p}/reports/:id`}
                component={ReportNewView}
              />
              <Route exact path={`${p}/targets`} component={TargetsView} />
              <Route exact path={`/targets/new`} component={TargetNewView} />
              <Route exact path={`/audios`} component={AudiosView} />
              <Route
                exact
                path={`/audios/:cust_id/:id`}
                component={AudioPreviewView}
              />
              <Route path="/login" component={LoginView} />
              <Route path="/access" component={Access} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
};

export default App;

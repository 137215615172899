import _ from "lodash";

export const columns = (options, filters, sorter) => {
  const data = [
    {
      title: "Наименование",
      dataIndex: "tic_name",
      sorter: true,
    },
    {
      title: "БИН",
      dataIndex: "cust_bin",
      sorter: true,
    },
    {
      title: "Лицевой счет",
      dataIndex: "treaty_number",
      sorter: true,
    },
    {
      title: "Статус",
      dataIndex: "service_active",
    },
    {
      title: "",
      dataIndex: "change_status",
    },
    {
      title: "Действия",
      dataIndex: "actions",
      width: 250,
    },
  ];

  if (options) {
    Object.keys(options).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1) data[index] = { ...data[index], ...options[dataIndex] };
    });
  }

  if (filters) {
    Object.keys(filters).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && filters[dataIndex])
        data[index].filteredValue = filters[dataIndex];
    });
  }

  if (sorter) {
    Object.keys(sorter).forEach((dataIndex) => {
      const index = _.findIndex(data, { dataIndex });
      if (index !== -1 && sorter[dataIndex]) {
        data[index].sortOrder = sorter[dataIndex];
        data[index].sorter = (a, b) => {
          if (!a[dataIndex]) a[dataIndex] = "";
          if (!b[dataIndex]) b[dataIndex] = "";
          return a[dataIndex].localeCompare(b[dataIndex]);
        };
      }
    });
  }

  return data;
};

import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./index.less";

const antIcon = (fontSize) => <LoadingOutlined style={{ fontSize }} spin />;

const Loading = () => (
  <div className="app__overlay">
    <div className="app__loading">{<Spinner />}</div>
  </div>
);

export const Spinner = (fontSize) => <Spin indicator={antIcon(fontSize)} />;

Spinner.defaultProps = {
  fontSize: 24,
};

export default Loading;

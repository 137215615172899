import React from "react";
import { useDispatch } from "react-redux";
import { Table, Button, Tag } from "antd";
import { columns } from "./data";
import {
  WarningOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { SET_APP } from "@/actions/app";

const TableComp = (props) => {
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
  });

  const dispatch = useDispatch();

  const [filters, setFilters] = React.useState(null);

  const [sorter, setSorter] = React.useState(null);

  const onChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setFilters(filters);
    setSorter({ [sorter.field]: sorter.order });
  };

  const onPreview = (item) => {
    dispatch(SET_APP(["audio"], item));
    props.history.push(`/audios/${item.cust_id}/${item.id}`);
  };

  const options = {
    status: {
      render: (val) => {
        if (val === "waiting")
          return (
            <Tag key="w" icon={<WarningOutlined />} color="warning">
              На проверке
            </Tag>
          );
        else if (val === "approved")
          return (
            <Tag key="s" icon={<CheckOutlined />} color="success">
              Готов к использованию
            </Tag>
          );
        else
          return (
            <Tag key="s" icon={<CloseOutlined />} color="error">
              Отказано в использовании
            </Tag>
          );
      },
    },
    actions: {
      render: (_, item) => {
        return (
          <div className="actions">
            <Button
              onClick={() => onPreview(item)}
              size="small"
              type="action_edit"
            >
              Подробнее
            </Button>
          </div>
        );
      },
    },
  };

  return (
    <Table
      size="small"
      className="striped-table"
      columns={columns(options, filters, sorter)}
      rowKey={(row) => row.id}
      {...props}
      pagination={pagination}
      onChange={onChange}
    />
  );
};

export default TableComp;

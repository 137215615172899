import React from "react";
import { connect, useDispatch } from "react-redux";
import { AUTH, SET_AUTH } from "@/actions/auth";
import { Form, Button, Typography, Input } from "antd";
import { Row, Col, Alert } from "antd";

import "./index.less";

const { Title } = Typography;

const Screen = (props) => {
  const { message, loading } = props;

  const dispatch = useDispatch();

  React.useEffect(() => {
    const [el] = document.getElementsByClassName("main__content__layout");
    el.classList.add("login");

    return () => {
      const [el] = document.getElementsByClassName("main__content__layout");
      el.classList.remove("login");
    };
  }, []);

  const handleSubmit = async (values) => {
    const { username, password } = values;
    dispatch(AUTH(username, password));
  };

  return (
    <Row
      justify="center"
      align="middle"
      type="flex"
      className="login-form-container"
    >
      <Col xl={6} xxl={6} lg={8} md={8} className="login-form-box">
        <Title level={4}>Авторизация</Title>
        <Form layout="vertical" onFinish={handleSubmit} className="login-form">
          {message && (
            <Form.Item>
              <Alert message={message} type="error" showIcon />
            </Form.Item>
          )}
          <Form.Item name="username" label="Логин">
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item name="password" label="Пароль">
            <Input type="password" disabled={loading} />
          </Form.Item>
          <Form.Item>
            <Button
              type="success"
              htmlType="submit"
              className="login-form-button"
              disabled={loading}
            >
              Войти
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  message: state.auth.message,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { AUTH, SET_AUTH })(Screen);

import React from "react";
import { useDispatch } from "react-redux";
import { Table, Button, Modal, notification } from "antd";
import { Tag } from "antd";
import { columns } from "./data";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { call } from "@/actions/axios";
import { GET_CLIENTS, SET_APP, REMOVE_APP_BY_PARAM } from "@/actions/app";

const { confirm } = Modal;

const TableComp = (props) => {
  const { history } = props;
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
  });

  const [filters, setFilters] = React.useState(null);

  const [sorter, setSorter] = React.useState(null);

  const onChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setFilters(filters);
    setSorter({ [sorter.field]: sorter.order });
  };

  const dispatch = useDispatch();

  const onPreview = (item) => {
    dispatch(SET_APP(["client"], item));
    history.push("/clients/" + item.cust_id + "/preview");
  };

  const onEdit = (cust_id) => {
    history.push("/clients/" + cust_id);
  };

  const changeStatus = async (cust_id, service_active) => {
    await dispatch(
      call({
        url: `/api/admin/customers/${cust_id}/change_status`,
        method: "POST",
        data: { service_active },
      })
    );
    dispatch(GET_CLIENTS());
  };

  const onStatus = async (cust_id, isActive) => {
    if (!isActive) {
      try {
        return await changeStatus(cust_id, 1);
      } catch (e) {
        return notification.info({
          message: "Ошибка!",
          description: `Не удалось подключить клиента`,
        });
      }
    }
    confirm({
      title: "Внимание!",
      icon: <ExclamationCircleOutlined />,
      content: `Вы действительно хотите отключить клиента?`,
      okText: "Да",
      async onOk() {
        try {
          await changeStatus(cust_id, 0);
        } catch (e) {
          return notification.info({
            message: "Ошибка!",
            description: `Не удалось отключить клиента`,
          });
        }
      },
      onCancel() {},
    });
  };

  const onDelete = (cust_id) => {
    confirm({
      title: "Внимание!",
      icon: <ExclamationCircleOutlined />,
      content: "Вы действительно хотите удалить клиента?",
      okText: "Да",
      async onOk() {
        try {
          await dispatch(
            call({
              url: `/api/admin/customers/${cust_id}`,
              method: "DELETE",
            })
          );
          dispatch(REMOVE_APP_BY_PARAM(["clients"], "cust_id", cust_id));
        } catch (e) {
          return notification.info({
            message: "Ошибка!",
            description: "Не удалось удалить клиента",
          });
        }
      },
      onCancel() {},
    });
  };

  const options = {
    service_active: {
      render: (val) => {
        if (val.toString() === "1") return <Tag color="green">Активный</Tag>;
        else return <Tag color="red">Неактивный</Tag>;
      },
    },
    change_status: {
      render: (_, item) => {
        const isActive = item.service_active.toString() === "1";
        return (
          <Button
            onClick={() => onStatus(item.cust_id, isActive)}
            size="small"
            type="primary"
          >
            {isActive ? "Отключить" : "Включить"}
          </Button>
        );
      },
    },
    actions: {
      render: (_, item) => {
        return (
          <div className="actions">
            <Button onClick={() => onPreview(item)} size="small" type="action">
              Посмотреть
            </Button>
            <Button
              onClick={() => onEdit(item.cust_id)}
              size="small"
              type="action_edit"
            >
              <EditOutlined />
            </Button>
            <Button
              onClick={() => onDelete(item.cust_id)}
              size="small"
              type="action_delete"
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  };

  return (
    <Table
      size="small"
      className="striped-table"
      columns={columns(options, filters, sorter)}
      rowKey={(row) => row.cust_id}
      {...props}
      pagination={pagination}
      onChange={onChange}
    />
  );
};

export default TableComp;

import { call } from "./axios";

export const SET_APP = (path, value) => ({ type: "SET_APP", path, value });
export const SET_APP_BY_PARAM = (path, param, value) => ({
  type: "SET_APP_BY_PARAM",
  path,
  param,
  value,
});
export const SET_APPS = (arr) => ({ type: "SET_APPS", arr });
export const PUSH_APP = (path, value) => ({
  type: "PUSH_APP",
  path,
  value,
});
export const UNSHIFT_APP = (path, value) => ({
  type: "UNSHIFT_APP",
  path,
  value,
});
export const REMOVE_APP_BY_PARAM = (path, key, value) => ({
  type: "REMOVE_APP_BY_PARAM",
  path,
  key,
  value,
});

export const GET_AONS = () => async (dispatch, getState) => {
  let { client } = getState().app;
  if (!client) return;
  dispatch(SET_APP(["aonsLoading"], true));
  const temp = [];
  try {
    const { data } = await dispatch(
      call({ url: "/api/admin/aons/numbers/" + client.cust_id })
    );

    const array = data.msisdn;
    Object.keys(array).forEach((key) =>
      temp.push({ ...array[key], msisdn: key })
    );
  } catch (e) {
    return dispatch(SET_APP(["aonsLoading"], false));
  }
  dispatch(
    SET_APPS([
      { path: ["aons"], value: temp },
      { path: ["aonsLoading"], value: false },
    ])
  );
};

export const GET_CLIENT = (cust_id) => async (dispatch) => {
  const { data } = await dispatch(
    call({ url: `/api/admin/customers/basic/${cust_id}` })
  );

  const { data: extraData } = await dispatch(
    call({ url: `/api/admin/customers/detailed/${cust_id}` })
  );
  return { ...data, ...extraData };
};

export const GET_CLIENTS = () => async (dispatch) => {
  dispatch(SET_APP(["clientsLoading"], true));
  const temp = [];
  try {
    const { data } = await dispatch(
      call({ url: "/api/admin/customers/basic" })
    );

    const { data: extraData } = await dispatch(
      call({ url: "/api/admin/customers/detailed" })
    );

    const array = data.cust_id;
    const extraArray = extraData.cust_id;
    Object.keys(array).forEach((key) =>
      temp.push({ ...array[key], cust_id: key, ...extraArray[key] })
    );
  } catch (e) {
    return dispatch(SET_APP(["clientsLoading"], false));
  }

  dispatch(
    SET_APPS([
      { path: ["clients"], value: temp },
      { path: ["clientsLoading"], value: false },
    ])
  );
};

export const GET_LISTS = () => async (dispatch, getState) => {
  const { client } = getState().app;
  dispatch(SET_APP(["listsLoading"], true));
  try {
    const { data } = await dispatch(
      call({ url: `/targetlist/requests/${client.cust_id}` })
    );
    dispatch(
      SET_APPS([
        { path: ["lists"], value: data },
        { path: ["listsLoading"], value: false },
      ])
    );
  } catch (e) {
    return dispatch(SET_APP(["listsLoading"], false));
  }
};

export const GET_AUDIOS = () => async (dispatch, getState) => {
  dispatch(SET_APP(["audiosLoading"], true));
  try {
    const { data } = await dispatch(call({ url: `/audios` }));
    dispatch(
      SET_APPS([
        { path: ["audios"], value: data },
        { path: ["audiosLoading"], value: false },
      ])
    );
  } catch (e) {
    return dispatch(SET_APP(["audiosLoading"], false));
  }
};

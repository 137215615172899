import Immutable from "immutable";
// import _ from "lodash";

const INITIAL_STATE = {
  isLoading: false,
  user: null,
};

export default (state = INITIAL_STATE, data) => {
  let newState;
  switch (data.type) {
    case "LOGOUT":
      return { ...INITIAL_STATE };
    case "SET_AUTH":
      newState = Immutable.fromJS(state).setIn(data.path, data.value);
      return newState.toJS();
    case "SET_AUTHS":
      newState = Immutable.fromJS(state);
      if (data.arr && data.arr.length !== 0) {
        data.arr.forEach((values) => {
          newState = newState.setIn(values.path, values.value);
        });
      }
      return newState.toJS();
    default:
      return state;
  }
};

import React from "react";
import { useSelector } from "react-redux";
import { PageHeader, Col, Card } from "antd";
import { Step1 } from "./Steps";
import "./index.less";

const Screen = (props) => {
  const client = useSelector((state) => state.app.client);
  React.useEffect(() => {
    if (!client) return props.history.push("/");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!client) return null;

  return (
    <Col span={16}>
      <PageHeader
        onBack={() => props.history.push(`/clients/${client.cust_id}/aons`)}
        title={client.tic_name}
        subTitle="Добавить группу"
      />
      <Card bordered={false} className="create-container">
        <Step1 />
      </Card>
    </Col>
  );
};

export default Screen;

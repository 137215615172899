import React from "react";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import { PageHeader, Card, Descriptions, Tag } from "antd";
import { Button, notification } from "antd";
import { EditOutlined, SyncOutlined } from "@ant-design/icons";
import { call } from "@/actions/axios";
import "./index.less";

const Screen = (props) => {
  const { client, types } = props;
  const [sessions, setSessions] = React.useState(0);
  const [syncing, setSyncing] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!client) return props.history.push("/");
    const onData = async () => {
      const { data } = await dispatch(
        call({
          url: `/api/admin/customers/sessions_info/${client.cust_id}`,
          method: "GET",
        })
      );
      setSessions(data.active_sessions);
    };
    onData();
    //
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!client) return null;

  const page = (page) => () => {
    const addPage = page ? `/${page}` : "";
    props.history.push(`/clients/${client.cust_id}${addPage}`);
  };

  const sync = async () => {
    setSyncing(true);
    try {
      await dispatch(
        call({
          url: `api/admin/customers/${client.cust_id}/sync_with_tic`,
          method: "POST",
        })
      );
      notification.success({
        message: "Успешно!",
        description: "Синхронизация прошла успешно",
      });
    } catch (e) {
      notification.info({
        message: "Ошибка!",
        description: "Не удалось синхронизировать клиента с биллингом",
      });
    }
    setSyncing(false);
  };

  const clientInfo = [];
  if (client.contact_person) clientInfo.push(client.contact_person);
  if (client.contact_person_phone) clientInfo.push(client.contact_person_phone);
  if (client.contact_person_email) clientInfo.push(client.contact_person_email);

  if (client.law_address === ", ") client.law_address = "";

  const renderStatus = (val) => {
    if (val.toString() === "1") return <Tag color="green">Активный</Tag>;
    else return <Tag color="red">Неактивный</Tag>;
  };

  const renderServices = (item) => {
    const val = item.allowed_campaign_types;
    if (!val || !_.isArray(val)) return "Нет";
    else {
      const services = [];
      val.forEach((value) => {
        const service = _.find(types, { value });
        if (service) services.push(service.text);
      });
      if (item.record_audio_allowed) services.push("Запись аудио");
      if (item.tts_allowed) services.push("Text to speech");
      if (item.crm_integration_allowed) services.push("Интеграция CRM");
      return services.map((s) => <div key={s}>{s}</div>);
    }
  };

  return (
    <>
      <PageHeader
        onBack={() => props.history.push("/")}
        title={client.tic_name}
        subTitle={[
          <Button key="edit" type="link" onClick={page()}>
            <EditOutlined />
            Редактировать
          </Button>,
          <Button key="sync" type="link" onClick={sync}>
            <SyncOutlined spin={syncing} />
            Синхронизация с биллингом
          </Button>,
        ]}
        extra={[
          <Button key="targets" type="success" onClick={page("targets")}>
            Списки таргетконтакта
          </Button>,
          <Button key="aons" type="success" onClick={page("aons")}>
            АОНы
          </Button>,
          <Button key="reports" type="success" onClick={page("reports")}>
            Отчеты
          </Button>,
        ]}
      />
      <Card bordered={false} className="create-container">
        <Descriptions bordered>
          <Descriptions.Item label="Название (Биллинг)">
            {client.cust_official_name}
          </Descriptions.Item>
          <Descriptions.Item label="Юридический адрес (Биллинг)" span={2}>
            {client.law_address}
          </Descriptions.Item>
          <Descriptions.Item label="БИН">{client.cust_bin}</Descriptions.Item>
          <Descriptions.Item label="Лицевой счет TIC" span={2}>
            {client.treaty_number}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <span>
                Единовременных разговоров
                <br />
                (SIP сессий), тек/макс
              </span>
            }
          >{`${sessions} из ${client.allowed_sessions}`}</Descriptions.Item>
          <Descriptions.Item label="Количество операторов, тек/лимит" span={2}>
            {`${client.operators_created} из ${client.operators_allowed_to_create}`}
          </Descriptions.Item>
          <Descriptions.Item label="Статус" span={3}>
            {renderStatus(client.service_active)}
          </Descriptions.Item>
          <Descriptions.Item label="Комментарий" span={3}>
            {client.admin_comment}
          </Descriptions.Item>
          <Descriptions.Item label="Контактное лицо" span={3}>
            {clientInfo.map((c) => (
              <div key={c}>{c}</div>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="Подключенные сервисы">
            {renderServices(client)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: state.app.client,
  types: state.app.types,
});

export default connect(mapStateToProps)(Screen);

import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { Button, Form, Input, Modal } from "antd";
import { Col, Row, notification } from "antd";
import { call } from "@/actions/axios";
import { CheckCircleOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { confirm } = Modal;

const Step = (props) => {
  const { history, match, client } = props;
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const { data, match } = props;
    const item = _.find(data, { id: match.params.id });
    if (item) form.current.setFieldsValue(item);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const form = React.useRef();
  const dispatch = useDispatch();

  if (!client) return null;

  const { cust_id } = client;

  const isNew = match.params.id === "new";

  const onSubmit = async () => {
    const values = await form.current.validateFields();
    setLoading(true);
    try {
      if (isNew) {
        await dispatch(
          call({
            url: "/api/admin/aons/sets/" + cust_id,
            method: "POST",
            data: values,
          })
        );
      } else {
        await dispatch(
          call({
            url: `/api/admin/aons/sets/${cust_id}/${match.params.id}`,
            method: "PATCH",
            data: values,
          })
        );
      }
    } catch (e) {
      setLoading(false);
      const description = isNew
        ? "Не удалось добавить группу АОН"
        : "Не удалось обновить группу АОН";
      return notification.info({
        message: "Ошибка!",
        description,
      });
    }

    setLoading(false);

    confirm({
      title: "Группа успешно " + (isNew ? "добавлена" : "обновлена"),
      icon: <CheckCircleOutlined className="alert-icon" />,
      content: "Желаете вернуться к списку групп операторов?",
      okText: "Добавить еще",
      cancelText: "Вернуться",
      onOk() {
        form.current.resetFields();
      },
      onCancel() {
        history.push(`/clients/${cust_id}/agroups`);
      },
    });
  };

  return (
    <>
      <Form layout="vertical" ref={form}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Наименование группы"
              name="a_number_aons_set_name"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input disabled={loading} placeholder="Введите текст" />
            </Form.Item>
            <Form.Item label="Описание" name="customer_comment">
              <TextArea disabled={loading} placeholder="Введите текст" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="buttons">
        <Button onClick={onSubmit} type="success" style={{ marginLeft: 14 }}>
          {isNew ? "Сохранить" : "Обновить"}
        </Button>
        <Button onClick={() => history.goBack()} type="light">
          Назад
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.app.agroups,
  client: state.app.client,
});

export default withRouter(connect(mapStateToProps)(Step));

import { axios_ldap } from "./axios";
import createAppStore from "@/store";

export const SET_AUTH = (path, value) => ({
  type: "SET_AUTH",
  path,
  value,
});

export const SET_AUTHS = (arr) => ({ type: "SET_AUTHS", arr });

export const LOGOUT = () => {
  return (dispatch) => {
    const { persistor } = createAppStore();
    localStorage.clear();
    persistor.purge();
    dispatch({ type: "LOGOUT" });
  };
};

export const AUTH = (username, password) => async (dispatch) => {
  dispatch(SET_AUTH(["loading"], true));

  setTimeout(() => {
    dispatch(
      SET_AUTHS([
        { path: ["loading"], value: false },
        { path: ["user"], value: { name: "test" } },
      ])
    );
  }, 1000);
};

export const AUTH_ = (username, password) => async (dispatch) => {
  dispatch(SET_AUTH(["loading"], true));
  try {
    const headers = {
      "Content-Type": "text/xml",
    };
    const dataStr = `<?xml version="1.0" encoding="UTF-8"?>
      <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:kz="http://kz.kcell.apps.pentagon.coda.ws">
        <soapenv:Header/>
        <soapenv:Body>
          <kz:authenticate>
              <username>${username}</username>
              <password>${password}</password>
          </kz:authenticate>
        </soapenv:Body>
    </soapenv:Envelope>`;
    const { data } = await axios_ldap.post(
      "https://ldb-kms.kcell.kz/coda/users?wsdl",
      dataStr,
      {
        headers,
      }
    );

    console.log(data);
  } catch (e) {
    console.log(e.response, e.message, e.data);
  }

  dispatch(SET_AUTH(["loading"], false));
};

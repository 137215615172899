import io from "socket.io-client";
import queryString from "query-string";
import defaults from "@/defaults";

const socket = (page) => {
  const query = queryString.stringify({
    web: "admin",
    page,
  });
  return io(defaults.cabinetURL, {
    query,
    transports: ["websocket", "polling"],
  });
};

export default socket;

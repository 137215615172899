import React from "react";
import { connect, useDispatch } from "react-redux";
import { PageHeader, Col, Card } from "antd";
import "./index.less";

const Screen = (props) => {
  const { client } = props;

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!client) return props.history.push("/");
    //
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!client) return;

  return (
    <Col span={16}>
      <PageHeader
        onBack={() => props.history.push("/")}
        title={client.tic_name}
      />
      <Card bordered={false} className="create-container"></Card>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  client: state.app.client,
});

export default connect(mapStateToProps)(Screen);

import React from "react";
import moment from "moment";
import WaveSurfer from "wavesurfer.js";
import { Button } from "antd";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import defaults from "@/defaults";
import "./index.less";

const AudioPlayer = (props) => {
  const { url } = props;
  const [playing, setPlaying] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  let waveform = React.useRef();

  React.useEffect(() => {
    if (!url) return false;
    waveform.current = WaveSurfer.create({
      barWidth: 3,
      cursorWidth: 1,
      container: "#waveform",
      backend: "WebAudio",
      height: 80,
      progressColor: defaults.color,
      responsive: true,
      waveColor: "#b1b1b1",
      cursorColor: defaults.color,
    });

    waveform.current.load(url);
    waveform.current.on("ready", () => {
      const duration = waveform.current.getDuration();
      setDuration(duration);
    });
    waveform.current.on("audioprocess", () => {
      const time = waveform.current.getCurrentTime();
      setTime(time);
    });
    waveform.current.on("finish", () => {
      waveform.current.seekTo(0);
      setTime(0);
      setPlaying(false);
    });
    return () => {
      waveform.current.stop();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!url) return null;

  const onPlay = () => {
    setPlaying(!playing);
    waveform.current.playPause();
  };
  let display = moment.utc(time * 1000).format("mm:ss");
  display += " / ";
  display += moment.utc(duration * 1000).format("mm:ss");

  return (
    <div className="audio-player">
      <Button type="primary" shape="circle" size="large" onClick={onPlay}>
        {playing ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
      </Button>
      <div className="audio-player-container">
        <div className="audio-tick-container">
          <div className="audio-ticks">
            <span>{display}</span>
          </div>
        </div>
        <div id="waveform" />
      </div>
    </div>
  );
};

export default AudioPlayer;

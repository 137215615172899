import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { Button, Form, Input, Modal, Select } from "antd";
import { MaskedInput } from "@/ui";
import { Col, Row, notification } from "antd";
import { call } from "@/actions/axios";
import { SET_APP, SET_APPS } from "@/actions/app";
import { CheckCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

const Step = (props) => {
  const { history, match, client, groups } = props;
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();
  const form = React.useRef();

  React.useEffect(() => {
    if (!client) return props.history.push("/");
    const { cust_id } = client;
    const onData = async () => {
      const { data: groups } = await dispatch(
        call({ url: "/api/admin/aons/sets/" + cust_id })
      );

      const temp = [];
      try {
        const array = groups.a_number_aons_set_id;
        Object.keys(array).forEach((key) =>
          temp.push({ ...array[key], id: key })
        );
      } catch (e) {}
      dispatch(
        SET_APPS([
          { path: ["agroups"], value: temp },
          { path: ["agroupsLoading"], value: false },
        ])
      );
    };
    onData();
    const { data, match } = props;
    const item = _.find(data, { msisdn: match.params.id });
    if (item) form.current.setFieldsValue(item);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!client) return null;

  const { cust_id } = client;

  const isNew = match.params.id === "new";

  const onSubmit = async () => {
    const values = await getValues();
    setLoading(true);
    try {
      if (isNew) {
        await dispatch(
          call({
            url: `/api/admin/aons/numbers/${cust_id}/${values.msisdn}`,
            method: "POST",
            data: values,
          })
        );
      } else {
        await dispatch(
          call({
            url: `/api/admin/aons/numbers/${cust_id}/${match.params.id}`,
            method: "PATCH",
            data: values,
          })
        );
      }
    } catch (e) {
      setLoading(false);
      const description = isNew
        ? "Не удалось добавить АОН"
        : "Не удалось обновить АОН";
      return notification.info({
        message: "Ошибка!",
        description,
      });
    }
    setLoading(false);

    confirm({
      title: "АОН успешно " + (isNew ? "добавлен" : "обновлен"),
      icon: <CheckCircleOutlined className="alert-icon" />,
      content: "Желаете вернуться к списку АОН?",
      okText: "Добавить еще",
      cancelText: "Вернуться",
      onOk() {
        form.current.resetFields();
      },
      onCancel() {
        history.push(`/clients/${cust_id}/aons`);
      },
    });
  };

  const getValues = async (type = "validate") => {
    let values;
    if (type === "validate") values = await form.current.validateFields();
    else values = await form.current.getFieldsValue();
    if (values.msisdn) values.msisdn = values.msisdn.replace(/[^\d]/g, "");

    return values;
  };

  const onGroup = async () => {
    const values = await getValues("get");
    dispatch(SET_APP(["aon"], values));
    localStorage.setItem("backUrl", `/clients/${cust_id}/aons/new`);
    history.push(`/clients/${cust_id}/agroups`);
  };

  return (
    <>
      <Form layout="vertical" ref={form}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="АОН"
              name="msisdn"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <MaskedInput
                mask="+7 111 111 11 11"
                disabled={loading || !isNew}
              />
            </Form.Item>
            <Form.Item
              label="Наименование АОН"
              name="display_name"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input disabled={loading} placeholder="Введите текст" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Группа"
              name="a_number_aons_set_id"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
              help={<Group onGroup={onGroup} />}
            >
              <Select disabled={loading} placeholder="Выберите группу">
                {groups.map((group) => (
                  <Option key={group.id} value={parseInt(group.id)}>
                    {group.a_number_aons_set_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Описание" name="customer_comment">
              <TextArea disabled={loading} placeholder="Введите текст" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="buttons">
        <Button onClick={onSubmit} type="success" style={{ marginLeft: 14 }}>
          {isNew ? "Сохранить" : "Обновить"}
        </Button>
        <Button onClick={() => history.goBack()} type="light">
          Назад
        </Button>
      </div>
    </>
  );
};

const Group = ({ onGroup }) => {
  return (
    <div>
      <Button className="info action" type="link" onClick={onGroup}>
        + добавить группу
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.app.aons,
  groups: state.app.agroups,
  client: state.app.client,
});

export default withRouter(connect(mapStateToProps)(Step));

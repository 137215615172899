const defaults = {
  baseURL: "https://dev2.3beep.io/",
  cabinetURL: "https://dev.3beep.io/",
  SSO_USERNAME: "app_autodialer_backend",
  SSO_PASSWORD: "Urhhhf$$1884NimH",
  SSO_API_URL: "https://accounts-admin-stage.kcell.kz/",
  color: "#6537ED",
};

export default defaults;

import React from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { Button, Form, Input, Modal } from "antd";
import { Col, Row, notification, Upload } from "antd";
import { call } from "@/actions/axios";
import { CheckCircleOutlined, PaperClipOutlined } from "@ant-design/icons";

const { Dragger } = Upload;
const { confirm } = Modal;

const Step = (props) => {
  const { history, client, token } = props;
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const dispatch = useDispatch();
  const form = React.useRef();

  if (!client) return null;

  const { cust_id } = client;

  const onSubmit = async () => {
    const values = await getValues();
    const formData = new FormData();
    const file = values.file.pop();
    formData.append("file", file.originFileObj);
    formData.append("title", values.title);
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-type": "multipart/form-data",
    };
    setLoading(true);
    try {
      await dispatch(
        call({
          url: `/targetlist/requests/${cust_id}`,
          method: "POST",
          data: formData,
          // params: { headers },
        })
      );
    } catch (e) {
      setLoading(false);
      return notification.info({
        message: "Ошибка!",
        description: "Не удалось добавить списки таргет контакта",
      });
    }
    setLoading(false);

    confirm({
      title: "Список успешно добавлен",
      icon: <CheckCircleOutlined className="alert-icon" />,
      content: "Желаете вернуться к спискам?",
      okText: "Добавить еще",
      cancelText: "Вернуться",
      onOk() {
        form.current.resetFields();
      },
      onCancel() {
        history.push(`/clients/${cust_id}/targets`);
      },
    });
  };

  const getValues = async (type = "validate") => {
    let values;
    if (type === "validate") values = await form.current.validateFields();
    else values = await form.current.getFieldsValue();
    if (values.msisdn) values.msisdn = values.msisdn.replace(/[^\d]/g, "");

    return values;
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) return e;
    return e && e.fileList;
  };

  const draggerProps = {
    beforeUpload: (f) => {
      setFile(f);
      return false;
    },
    fileList: [file],
  };

  return (
    <>
      <Form layout="vertical" ref={form}>
        <Row gutter={32}>
          <Col span={16}>
            <Form.Item
              label="Название"
              name="title"
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Input disabled={loading} placeholder="Введите текст" />
            </Form.Item>
            <Form.Item
              label="Загрузка файла"
              name="file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required: true, message: "Данное поле обязательно" }]}
            >
              <Dragger
                multiple={false}
                showUploadList={false}
                {...draggerProps}
              >
                <p className="ant-upload-drag-icon">
                  <PaperClipOutlined />
                </p>
                {file ? (
                  <p className="ant-upload-text">{file.name}</p>
                ) : (
                  <>
                    <p className="ant-upload-text">
                      Перетяните сюда файл, чтобы загрузить (в формате xls,
                      xlsx, csv)
                    </p>
                    <p className="ant-upload-hint">
                      Или нажмите, чтобы открыть файловый менеджер
                    </p>
                  </>
                )}
              </Dragger>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="buttons">
        <Button onClick={onSubmit} type="success" style={{ marginLeft: 14 }}>
          Сохранить
        </Button>
        <Button onClick={() => history.goBack()} type="light">
          Назад
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: state.app.client,
  token: state.auth.token,
});

export default withRouter(connect(mapStateToProps)(Step));

import React from "react";
import { connect, useDispatch } from "react-redux";
import { PageHeader, Button, Card } from "antd";
import Table from "./Table";
import { GET_LISTS } from "@/actions/app";
import "./index.less";

const Screen = (props) => {
  const { client, data, loading, history } = props;
  const onCreate = () => {
    props.history.push("/targets/new");
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!client) return props.history.push(`/`);
    dispatch(GET_LISTS());
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!client) return;

  return (
    <>
      <PageHeader
        onBack={() => props.history.push(`/clients/${client.cust_id}/preview`)}
        title={client.tic_name}
        subTitle="Списки таргетконтакта"
        extra={[
          <Button key="create" type="success" onClick={onCreate}>
            Добавить список
          </Button>,
        ]}
      />
      <Card bordered={false} className="create-container">
        <Table dataSource={data} loading={loading} history={history} />
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: state.app.client,
  data: state.app.lists,
  loading: state.app.listsLoading,
});

export default connect(mapStateToProps)(Screen);

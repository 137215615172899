import React from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { PageHeader, Menu, Dropdown, Card } from "antd";
import Table from "./Table";
import { SET_APP, GET_AONS } from "@/actions/app";
import "./index.less";

const Screen = (props) => {
  const { data, history, loading, client } = props;

  const onMenu = () => {
    localStorage.setItem("backUrl", `/clients/${client.cust_id}/aons`);
    history.push(`/clients/${client.cust_id}/agroups`);
  };

  const menu = (
    <Menu onClick={onMenu}>
      <Menu.Item key="aons">Список групп АОН</Menu.Item>
    </Menu>
  );

  const onCreate = () => {
    history.push(`/clients/${client.cust_id}/aons/new`);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!client) return props.history.push("/");
    dispatch(SET_APP(["aon"], null));
    dispatch(GET_AONS());
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!client) return null;

  return (
    <>
      <PageHeader
        onBack={() => props.history.push(`/clients/${client.cust_id}/preview`)}
        title={client.tic_name}
        subTitle="Список АОН"
        extra={[
          <Dropdown.Button
            key="create"
            type="success"
            onClick={onCreate}
            overlay={menu}
          >
            Добавить АОН
          </Dropdown.Button>,
        ]}
      />
      <Card bordered={false}>
        <Table dataSource={data} loading={loading} history={props.history} />
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: state.app.client,
  data: state.app.aons,
  loading: state.app.aonsLoading,
});

export default connect(mapStateToProps)(Screen);
